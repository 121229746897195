const btn = document.querySelectorAll('#gamburger-knopka');
const menu = document.getElementById('bokovoe-menu');

btn.forEach(
    el => {
        el.addEventListener('click', () => {
            menu.classList.toggle('bokovoe-menu-otkryto');
        });
    }
)
